// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'VuetifyStepperContent',
  mixins: [uiMixin],
  props: {
    // Variables del componente padre "VuetifyStepper"
    item: {
      required: true,
      type: Object,
      default: {}
    },
    itemsData: {
      type: Object,
      default() {
        return {}
      }
    },
    step: {
      type: Number,
      default: 0
    },
    totalSteps: {
      required: true,
      type: Number,
      default: 0
    }
  },
  computed: {
    /**
     * "Componente" del item que nos llega
     *
     * @returns {object}
     */
    currentComponent() {
      // eslint-disable-next-line no-unused-vars
      const { component, ...others } = this.item
      return component
    },
    /**
     * Atributos del item, eliminando el "componente"
     *
     * @returns {object} - atributos del item (paso) donde nos encontramos
     */
    currentItem() {
      // eslint-disable-next-line no-unused-vars
      const { component, ...others } = this.item
      return others
    },
    /**
     * Etiqueta del botón "Anterior"
     *
     * @returns {boolean}
     */
    labelPrevButton() {
      return 'Anterior'
    },
    /**
     * Etiqueta del botón "Siguiente"
     *
     * @returns {boolean}
     */
    labelNextButton() {
      return this.isFinalStep ? 'Finalizar' : 'Siguiente'
    },
    /**
     * El botón de "Anterior" es visible?
     *
     * @returns {boolean}
     */
    isPrevButtonVisible() {
      return this.step > 1
    },
    /**
     * El botón de "Siguiente" es visible?
     *
     * @returns {boolean}
     */
    isNextButtonVisible() {
      return this.totalSteps >= this.step
    },
    /**
     * Es el paso final
     *
     * @returns {boolean}
     */
    isFinalStep() {
      return this.step === this.totalSteps
    },
    /**
     * Datos a enviar al componente hijo
     *
     * @returns {object}
     */
    paramsToChildComponent() {
      return {
        finalStep: this.isFinalStep,
        item: this.currentItem,
        itemsData: this.itemsData,
        labelPrevButton: this.labelPrevButton,
        labelNextButton: this.labelNextButton,
        step: this.step,
        totalSteps: this.totalSteps,
        visiblePrevButton: this.isPrevButtonVisible,
        visibleNextButton: this.isNextButtonVisible
      }
    }
  },
  methods: {
    /**
     * Cuando pulsamos sobre el botón "siguiente"
     *
     * @param {Function} actionFn - función que se lanzará tras pulsar en este
     */
    async handleClickNextButton(actionFn) {
      if (this.isFinalStep) {
        // Mensaje de advertencia antes de lanzar función
        this.modifyAppAlert({
          actionButtonFn: async () => {
            await actionFn()
          },
          actionButtonText: 'Finalizar',
          dismissibleTimeOut: -1,
          text: '¿Seguro que deseas finalizar el proceso?',
          type: 'warning',
          visible: true
        })
      } else {
        await actionFn()
      }
    },
    /**
     * Cuando pulsamos sobre el botón "anterior"
     */
    handleClickPrevButton() {
      this.handleChangeStep(this.step - 1)
    },
    /**
     * Evento lanzado tras cambio de "step"
     *
     * @param {Number | String} step - child step
     */
    handleChangeStep(step) {
      // Transmitimos el evento al padre
      this.$parent.$parent.$parent.$emit('onChangeStep', step)
    },
    /**
     * Evento lanzado tras modificar el objeto que registra los campos
     * almacenados por cada uno de los componentes (item) del Stepper
     *
     * @param {Number | string} step - child step
     * @param {number | string | object | array} data - child step response
     */
    handleChangeItemsData(step, data) {
      // Transmitimos el evento al padre
      this.$parent.$parent.$parent.$emit('onChangeItemsData', step, data)
    },
    /**
     * Evento lanzado para resetear todos los campos almacenado en "itemsData"
     */
    handleResetItemsData() {
      // Transmitimos el evento al padre
      this.$parent.$parent.$parent.$emit('onResetItemsData')
    }
  }
}
