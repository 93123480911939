// Components
import FormButtons from '@/components/ui/FormButtons'
import CameraUploader from '@/components/ui/CameraUploader'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
// QR
import { QrcodeStream } from 'vue-qrcode-reader'
// Utils
import { isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'activationFormStepQr',
  components: { FormButtons, CameraUploader, QrcodeStream },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Variables del componente padre "VuetifyStepperContent"
    finalStep: {
      required: true,
      type: Boolean,
      default: false
    },
    item: {
      required: true,
      type: Object,
      default: {}
    },
    itemsData: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    labelPrevButton: {
      required: true,
      type: String,
      default: 'Anterior'
    },
    labelNextButton: {
      required: true,
      type: String,
      default: 'Siguiente'
    },
    step: {
      required: true,
      type: Number,
      default: 0
    },
    totalSteps: {
      required: true,
      type: Number,
      default: 0
    },
    visibleNextButton: {
      required: true,
      type: Boolean,
      default: true
    },
    visiblePrevButton: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // Form
      formFields: {
        placeUrl: null,
        images: null
      },
      formFieldsValidations: {
        placeUrl: {
          required: 'Campo obligatorio',
          url: 'No es un formato de url valido'
        },
        images: {
          requiredArray: 'Es necesario subir al menos una imagen'
        }
      },
      // Others
      importMethod: 'img',
      importMethods: [
        {
          id: 'url',
          name: 'Mediante enlace o qr'
        },
        {
          id: 'img',
          name: 'Mediante imagenes'
        }
      ],
      // QR
      qrIsActive: false
    }
  },
  computed: {
    /**
     * Información que mostramos al usuario según el método de importación elegido
     *
     * @return {string}
     */
    importMethodInfo() {
      return this.importMethod === 'url'
        ? 'Introduce el enlace a la carta actual o pulsa sobre el QR para escanear el código directamente.'
        : 'Pulsa en subir fotos y selecciona tantas imágenes como sea necesario. Comprueba que las fotos se ven correctamente y con la suficiente calidad.'
    },
    /**
     * Definimos en que ruta se almacenará las imágenes
     *
     * @return {string}
     */
    pathToSaveImg() {
      return `activations/${getEnvironmentVariable('VUE_APP_BRAND')}`
    }
  },
  watch: {
    itemsData(value) {
      if (!isNil(value) && typeof value === 'object' && Object.keys(value).length === 0) {
        // Reset fields and validation
        this.formFields = {
          placeUrl: null,
          images: null
        }
        this.$v.$reset()
        // Reset images in CameraUploader
        this.$refs.cameraUploader.onResetImages()
      }
    }
  },
  methods: {
    /**
     * Tratamos las imágenes subidas
     * @param {array} images - imágenes subidas en el componente
     */
    handleImages(images) {
      this.formFields.images = images
    },
    /**
     * Cuando pulsamos sobre el botón "cancelar" (anterior)
     */
    handleClickCancelButton() {
      this.$emit('onClickPrevButton')
    },
    /**
     * Cuando pulsamos sobre el botón "aceptar" (siguiente)
     */
    handleClickAcceptButton() {
      this.$emit('onClickNextButton', this.onSubmit)
    },
    /**
     * Manejador de posibles errores del componente de QR
     *
     * @param {object} promise
     */
    async handleQrErrors(promise) {
      try {
        await promise
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      }
    },
    /**
     * Manejador para cuando el componente QR detecta la url que "encierra"
     * el códido (descodificándolo)
     *
     * @param {string} value - valor "descodificado" por el componente
     */
    async handleQrDecode(value) {
      this.formFields.placeUrl = value
      this.qrIsActive = false
    },
    /**
     * El componente QR se encuentra activo (visible)
     */
    handleQrIsActive() {
      this.qrIsActive = !this.qrIsActive
    },
    /**
     * Lanzado en el submit del formulario tras las validaciones
     */
    async afterSubmit() {
      try {
        // Almacenamos los datos recogidos en el proceso,
        // usamos como "id" dentro del objeto, el nombre de la sección
        this.$emit('onChangeItemsData', 'activationFormStepQr', {
          ...this.formFields
        })
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {}
    }

    if (this.importMethod === 'url') {
      rules.formFields = {
        placeUrl: {
          required,
          url
        }
      }
    }

    if (this.importMethod === 'img') {
      rules.formFields = {
        images: {
          // Comprueba si hay al menos una imagen subida
          requiredArray: (value) => {
            if (!value || !Array.isArray(value) || value.length <= 0) {
              return false
            }
            return true
          }
        }
      }
    }

    return rules
  }
}
