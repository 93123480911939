// Components
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// Utils
import { isNil } from 'lodash'

export default {
  name: 'ActivationFormStepCompanyData',
  components: { FormButtons, GoogleMapAutocomplete },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Variables del componente padre "VuetifyStepperContent"
    finalStep: {
      required: true,
      type: Boolean,
      default: false
    },
    item: {
      required: true,
      type: Object,
      default: {}
    },
    itemsData: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    labelPrevButton: {
      required: true,
      type: String,
      default: 'Anterior'
    },
    labelNextButton: {
      required: true,
      type: String,
      default: 'Siguiente'
    },
    step: {
      required: true,
      type: Number,
      default: 0
    },
    totalSteps: {
      required: true,
      type: Number,
      default: 0
    },
    visibleNextButton: {
      required: true,
      type: Boolean,
      default: true
    },
    visiblePrevButton: {
      required: true,
      type: Boolean,
      default: true
    }
  },

  data() {
    // Form
    return {
      formFields: {
        name: null,
        placeName: null,
        email: null,
        phone: null,
        place: null
      },
      formFieldsValidations: {
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        name: {
          required: 'Campo obligatorio'
        },
        phone: {
          required: 'Campo obligatorio'
        },
        place: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        },
        placeName: {
          required: 'Campo obligatorio'
        }
      }
    }
  },
  watch: {
    itemsData(value) {
      if (!isNil(value) && typeof value === 'object' && Object.keys(value).length === 0) {
        // Reset form fields
        this.formFields = {
          email: null,
          name: null,
          phone: null,
          place: null,
          placeName: null
        }
        // Reset validations
        this.$v.$reset()
      }
    }
  },
  methods: {
    /**
     * Cuando pulsamos sobre el botón "cancelar" (anterior)
     */
    handleClickCancelButton() {
      this.$emit('onClickPrevButton')
    },
    /**
     * Cuando pulsamos sobre el botón "aceptar" (siguiente)
     */
    handleClickAcceptButton() {
      this.$emit('onClickNextButton', this.onSubmit)
    },
    /**
     * Lanzado en el submit del formulario tras las validaciones
     */
    async afterSubmit() {
      try {
        // Se guardan los datos para utilziarlos al final del proceso
        this.$emit('onChangeItemsData', 'activationFormStepCompanyData', {
          ...this.formFields
        })
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      email: {
        required,
        email
      },
      name: {
        required
      },
      phone: {
        required
      },
      place: {
        required
      },
      placeName: {
        required
      }
    }
  }
}
