// Constantes
import { ACTIVATION_STATUS, ACTIVATION_TYPES } from '@/constants'
// Components
// import ActivationFormStepCompanyKey from '@/components/elements/activations/ActivationFormStepCompanyKey'
import ActivationFormStepCompanyData from '@/components/elements/activations/ActivationFormStepCompanyData'
// import ActivationFormStepActivationType from '@/components/elements/activations/ActivationFormStepActivationType'
// import ActivationFormStepStickers from '@/components/elements/activations/ActivationFormStepStickers'
import ActivationFormStepQr from '@/components/elements/activations/ActivationFormStepQr'
// import ActivationFormStepSupport from '@/components/elements/activations/ActivationFormStepSupport'
import BrandCardCentered from '@/components/ui/BrandCardCentered'
import VuetifyStepper from '@/components/ui/VuetifyStepper'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Services
import { createCompanyActivation } from '@/services/companyActivation'
// import { updateCompanyKeyById } from '@/services/companyKey'
// Utils
import { isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'ActivationForm',
  components: {
    BrandCardCentered,
    VuetifyStepper
  },
  mixins: [uiMixin],
  computed: {
    /**
     * Items (secciones) del stepper
     *
     * @return {Array} - diffent labels
     */
    stepperItems() {
      let items = []

      // Secciones de Bakarta
      if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
        items = [
          {
            component: ActivationFormStepCompanyData,
            id: 'activationFormStepCompanyData',
            label: 'Información'
          },
          {
            component: ActivationFormStepQr,
            id: 'activationFormStepQr',
            label: 'Carta'
          }
        ]
      }

      return items
    }
  },
  methods: {
    /**
     * Evento lanzado cuando el stepper ha finalizado
     *
     * @param {array} data - datos obtenidos de los items del stepper
     */
    async handleFinish(data) {
      // Recogemos todos los datos de los diferentas pasos
      const parseData = Object.entries(data).reduce((accFields, fields) => {
        return { ...accFields, ...fields[1] }
      }, {})
      // Guardamos la activación en base de datos (incluimos otros datos necesarios)
      await createCompanyActivation({
        ...parseData,
        brand: getEnvironmentVariable('VUE_APP_BRAND'),
        status: ACTIVATION_STATUS.pending.value,
        ...(isNil(parseData.type) ? { type: ACTIVATION_TYPES.assistant.value } : {})
      })

      // TODO - Enviamos correo

      // Volvemos al principio
      this.$parent.$parent.$parent.$emit('onChangeStep', 1)

      this.modifyAppAlert({
        text: 'Proceso de activación completado.',
        visible: true
      })
    }
  }
}
