// Components
import VuetifyStepperContent from '../VuetifyStepperContent'
// Utils
import { cloneDeep } from 'lodash'

export default {
  name: 'VuetifyStepper',
  components: {
    VuetifyStepperContent
  },
  props: {
    // Differents "section" inside stepper
    // https://vuetifyjs.com/en/components/steppers/
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      step: 1, // Current step in "stepper"
      itemsData: {} // Global data collected from every "stepper childs"
    }
  },
  mounted() {
    // We listen the child responses
    this.$on('onResetItemsData', this.handleResetItemsData)
    this.$on('onChangeItemsData', this.handleChangeItemsData)
    this.$on('onChangeStep', this.handleChangeStep)
  },
  methods: {
    /**
     * Change the current "step" in "custom stepper"
     *
     * @param {Number | String} step - child step
     */
    handleChangeStep(step) {
      // "id" de la sección
      if (typeof step === 'string') {
        const itemIndex = this.items.findIndex((item) => {
          return item.id === step
        })

        if (itemIndex > -1) {
          step = itemIndex + 1
        }
      }

      this.step = step > 0 ? step : 1
    },
    /**
     * Parse the "itemsData" to use in "stepper childs"
     *
     * @param {Number | string} step - child step
     * @param {number | string | object | array} data - child step response
     */
    handleChangeItemsData(step, data) {
      let currentStep = 1

      if (typeof step === 'string') {
        const itemIndex = this.items.findIndex((item) => {
          return item.id === step
        })

        if (itemIndex > -1) {
          currentStep = itemIndex + 1
        }
      } else if (typeof step === 'number') {
        currentStep = step > 0 ? step : 1
      }

      // Siguiente sección
      const nextStep = currentStep + 1

      // Almacenamos resultado en el objeto
      this.itemsData[step] = data
      // Para que los "watchers" reaccionen
      this.itemsData = cloneDeep(this.itemsData)

      if (nextStep > this.items.length) {
        // Evento de paso final (reunión de datos)
        this.$emit('onFinish', this.itemsData)
        // Reseteamos los datos recogidos
        this.handleResetItemsData()
      } else {
        // Siguiente sección
        this.step = nextStep
      }
    },
    /**
     * Reset the "itemsData" to use in "stepper childs"
     *
     */
    handleResetItemsData() {
      this.itemsData = {}
      this.step = 1
    }
  }
}
